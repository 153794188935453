import React from 'react';
import TopHeader from './TopHeader';
import { Link, useLocation } from 'react-router-dom';
import './AdminDashboard.css';

const AdminHeader = () => {
  const location = useLocation();

  return (
    <>
      <TopHeader />
      <div className="Mainheader">
        <div className="menu">
          <Link to="/admin" className={`nav-link ${location.pathname === '/admin' ? 'active' : ''}`}>HOME</Link>
          <Link to="/admin/work-orders" className={`nav-link ${location.pathname === '/admin/work-orders' || location.pathname === '/admin/work-orders/add-new-work-order' ? 'active' : ''}`}>WORK ORDER</Link>
          <Link to="/admin/purchase-orders" className={`nav-link ${location.pathname === '/admin/purchase-orders' || location.pathname === '/admin/purchase-orders/add-new-purchase-order' ? 'active' : ''}`}>PURCHASE ORDER</Link>
          <Link to="/admin/ready-to-invoice" className={`nav-link ${location.pathname === '/admin/ready-to-invoice' ? 'active' : ''}`}>INVOICE</Link>
          <Link to="/admin/resource-allocation" className={`nav-link ${location.pathname === '/admin/resource-allocation' ? 'active' : ''}`}>RESOURCE ALLOCATION</Link>
          <Link to="/admin/completed-jobs" className={`nav-link ${location.pathname === '/admin/completed-jobs' ? 'active' : ''}`}>COMPLETED JOBS</Link>


        </div>
        <div className="menu-left">
          <button onClick={() => window.location.reload()}>REFRESH</button>
          <button onClick={() => {
            localStorage.removeItem('token');
            window.location.href = '/';
          }}>LOG OUT</button>
        </div>
      </div>
    </>
  );
};

export default AdminHeader;
