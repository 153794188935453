import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'; // Changed from useParams to useLocation
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { format, compareAsc, isValid, parse } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../AdminDashboard.css';
import './ResourceAllocation.css';

// Styled Components
const inputHeight = '55px'; // Adjust this to change the height of all input fields

const StyledFormControl = styled(FormControl)`
  margin-top: 16px;
  margin-bottom: 8px;
  .MuiInputBase-root {
    height: ${inputHeight};
  }
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`;

const LabourContainer = styled.div`
  margin-top: 20px;
`;

const LabourFields = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
`;

const Heading = styled.h2`
  color: black;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#000000',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'left',
  },
}));

// Helper functions to get the start and end of the first 30 days of the current month
const getStartOfMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1);
};

const getEndOfFirst30Days = () => {
  const start = getStartOfMonth();
  const end = new Date(start);
  end.setDate(start.getDate() + 29); // 30 days total (including the start date)

  // Ensure the end date does not exceed the current month
  const lastDayOfMonth = new Date(start.getFullYear(), start.getMonth() + 1, 0);
  if (end > lastDayOfMonth) {
    return lastDayOfMonth;
  }
  return end;
};

// Define ownerAndResourceOptions outside the component to prevent re-creation on each render
const ownerAndResourceOptions = ['Kishan', 'Jagulan'];

function ResourceAllocation() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const workOrderNo = query.get('workOrderNo') || ''; // Extract workOrderNo from query params

  // State Variables
  const [value, setValue] = useState(0); // Tab index
  const [workOrder, setWorkOrder] = useState('');
  const [resources, setResources] = useState([]);
  const [scopeOfWorks, setScopeOfWorks] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [finishTime, setFinishTime] = useState(null);
  const [scheduledBy, setScheduledBy] = useState('');
  const [owner, setOwner] = useState(''); // Define 'owner' state
  const [programmedWorkOrders, setProgrammedWorkOrders] = useState([]);
  const [normalWorkOrders, setNormalWorkOrders] = useState([]); // New state for normal work orders
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);

  const [dateRange, setDateRange] = useState({
    startDate: getStartOfMonth(),
    endDate: getEndOfFirst30Days(),
  });

  const [labourEntries, setLabourEntries] = useState([
    { additionalLabour: '', labourCompany: '' },
  ]);
  const [attendanceIdentifier, setAttendanceIdentifier] = useState('');

  const technicianRefs = useRef(
    ownerAndResourceOptions.reduce((acc, name) => {
      acc[name] = React.createRef();
      return acc;
    }, {})
  );

  // Fetched Fields
  const [account, setAccount] = useState('');
  const [address, setAddress] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [clientType, setClientType] = useState('');

  // New Hidden Fields (Not displayed in frontend)
  const [contactPhone, setContactPhone] = useState('');
  const [contactMobile, setContactMobile] = useState('');
  const [siteContactEmail, setSiteContactEmail] = useState('');
  const [jobCategory, setJobCategory] = useState('');

  // Backend Data States
  const [scheduledJobs, setScheduledJobs] = useState([]);
  const [resourceAllocations, setResourceAllocations] = useState([]);

  // Loading States
  const [isLoadingScheduledJobs, setIsLoadingScheduledJobs] = useState(false);
  const [isLoadingResourceAllocations, setIsLoadingResourceAllocations] = useState(false);

  // Retrieve the username from localStorage
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setScheduledBy(storedUsername);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchWorkOrders = async () => {
      try {
        // Fetch programmed work orders
        const programmedResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/numbers`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (!programmedResponse.ok) throw new Error('Failed to fetch programmed work order numbers');
        const programmedData = await programmedResponse.json();
        setProgrammedWorkOrders(programmedData); // Set programmed work orders state

        // Fetch normal work orders
        const normalResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/work-orders/numbers`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (!normalResponse.ok) throw new Error('Failed to fetch normal work order numbers');
        const normalData = await normalResponse.json();
        setNormalWorkOrders(normalData); // Set normal work orders state

        // If workOrderNo is available from URL, set the work order and fetch details
        if (workOrderNo) {
          if (programmedData.includes(workOrderNo)) {
            setWorkOrder(workOrderNo);
            fetchWorkOrderDetails(workOrderNo, true);
          } else if (normalData.includes(workOrderNo)) {
            setWorkOrder(workOrderNo);
            fetchWorkOrderDetails(workOrderNo, false);
          } else {
            console.warn(`Work Order No ${workOrderNo} not found in either programmed or normal work orders.`);
          }
        }
      } catch (error) {
        console.error('Error fetching work order numbers:', error);
      }
    };

    fetchWorkOrders(); // Call the function to fetch both programmed and normal work orders
  }, [workOrderNo]); // Re-run if workOrderNo changes

  // Function to fetch work order details based on type
  const fetchWorkOrderDetails = async (workOrderNumber, isProgrammed) => {
    const token = localStorage.getItem('token');
    try {
      let response;
      if (isProgrammed) {
        // Fetch details for programmed work order
        response = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/details/${encodeURIComponent(workOrderNumber)}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        // Fetch details for normal work order
        response = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/work-orders/details/${encodeURIComponent(workOrderNumber)}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }

      if (!response.ok) {
        throw new Error('Failed to fetch work order details');
      }

      const data = await response.json();

      // Set the fetched data in the state
      setAccount(data.account || '');
      setAddress(data.address || '');
      setContactName(data.contactName || '');
      setContactPhone(data.contactPhone || '');
      setContactMobile(data.contactMobile || '');
      setSiteContactEmail(data.siteContactEmail || '');
      setClientType(data.clientType || '');
      setJobCategory(data.jobCategory || '');

      // Set 'scopeOfWorks' to 'notes' for programmed work orders, or 'description' for normal work orders
      setScopeOfWorks(data.notes || data.description || '');

      // Fetch attendance identifier from backend
      const attendanceResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/resource-allocation/attendance-id/${encodeURIComponent(workOrderNumber)}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!attendanceResponse.ok) {
        throw new Error('Failed to fetch attendance identifier');
      }

      const attendanceData = await attendanceResponse.text();
      setAttendanceIdentifier(attendanceData);
    } catch (error) {
      console.error('Error fetching work order details:', error);
      // Reset fields in case of error
      setAccount('');
      setAddress('');
      setContactName('');
      setContactPhone('');
      setContactMobile('');
      setSiteContactEmail('');
      setClientType('');
      setJobCategory('');
      setScopeOfWorks('');
      setAttendanceIdentifier('');
      alert('Error fetching work order details. Please try again.');
    }
  };

  // Handle Work Order Selection Change
  const handleWorkOrderChange = async (e) => {
    const selectedWorkOrderNumber = e.target.value;
    setWorkOrder(selectedWorkOrderNumber);

    const isProgrammed = programmedWorkOrders.includes(selectedWorkOrderNumber);
    const isNormal = normalWorkOrders.includes(selectedWorkOrderNumber);

    if (isProgrammed) {
      await fetchWorkOrderDetails(selectedWorkOrderNumber, true);
    } else if (isNormal) {
      await fetchWorkOrderDetails(selectedWorkOrderNumber, false);
    } else {
      console.warn(`Selected Work Order No ${selectedWorkOrderNumber} not found in either programmed or normal work orders.`);
      // Optionally, reset fields
      setAccount('');
      setAddress('');
      setContactName('');
      setContactPhone('');
      setContactMobile('');
      setSiteContactEmail('');
      setClientType('');
      setJobCategory('');
      setScopeOfWorks('');
      setAttendanceIdentifier('');
    }
  };

  // Handle Tab Change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch Scheduled Jobs when "Scheduled Jobs" Tab is Active
  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchScheduledJobs = async () => {
      setIsLoadingScheduledJobs(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/resource-allocation/all`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch scheduled jobs');
        }

        const data = await response.json();
        setScheduledJobs(data);
      } catch (error) {
        console.error('Error fetching scheduled jobs:', error);
        alert('Failed to fetch scheduled jobs. Please try again later.');
      } finally {
        setIsLoadingScheduledJobs(false);
      }
    };

    if (value === 1) {
      fetchScheduledJobs();
    }
  }, [value]);

  // Fetch Resource Allocations when "Resources" Tab is Active and Date Range is Selected
  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchResourceAllocations = async () => {
      setIsLoadingResourceAllocations(true);
      try {
        const { startDate, endDate } = dateRange;

        // Convert start and end dates to "dd-MM-yyyy" format
        const formattedStartDate = format(startDate, 'dd-MM-yyyy');
        const formattedEndDate = format(endDate, 'dd-MM-yyyy');
        const allocations = [];

        for (const resourceName of ownerAndResourceOptions) {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/admin/resource-allocation/resource-schedules?resourceName=${encodeURIComponent(resourceName)}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );

          if (!response.ok) {
            throw new Error(`Failed to fetch allocations for ${resourceName}`);
          }

          const data = await response.json();
          allocations.push({ resourceName, schedules: data });
        }

        setResourceAllocations(allocations);
      } catch (error) {
        console.error('Error fetching resource allocations:', error);
        alert('Failed to fetch resource allocations. Please try again later.');
      } finally {
        setIsLoadingResourceAllocations(false);
      }
    };

    if (value === 2 && dateRange) {
      fetchResourceAllocations();
    }
  }, [value, dateRange]);

  // Handle Work Order Click in Scheduled Jobs Tab
  const handleWorkOrderClick = (allocation) => {
    setSelectedWorkOrder(allocation);
  };

  // Function to Format Resources for Display
  const formatResources = (resourcesList) => {
    return resourcesList.join(', ');
  };

  // Handle Form Submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation
    if (!workOrder) {
      window.alert('Please fill in the Work Order No');
      return;
    }

    if (!scopeOfWorks) {
      window.alert('Please fill in the Scope of Works');
      return;
    }
    if (!startTime) {
      window.alert('Please select the Start Time');
      return;
    }
    if (!finishTime) {
      window.alert('Please select the Finish Time');
      return;
    }
    if (!scheduledBy) {
      window.alert('Please select Scheduled By');
      return;
    }
    if (!owner) {
      window.alert('Please select the Owner');
      return;
    }
    if (!attendanceIdentifier) {
      window.alert('Attendance Identifier is not set');
      return;
    }

    // Ensure finishTime is after startTime
    if (finishTime <= startTime) {
      window.alert('Finish Time must be after Start Time');
      return;
    }

    // Format dates as "DD-MM-YYYY HH:MM"
    const formattedStartTime = format(startTime, 'dd-MM-yyyy HH:mm');
    const formattedFinishTime = format(finishTime, 'dd-MM-yyyy HH:mm');
    const formattedLastModifiedAt = format(new Date(), 'dd-MM-yyyy HH:mm');

    // Construct the labour entries as strings
    const labourStrings = labourEntries
      .filter(entry => entry.additionalLabour !== '' && entry.labourCompany !== '')
      .map(entry => `+${entry.additionalLabour}(${entry.labourCompany})`);

    // Combine owner, resources, and labour entries into an array
    const resourcesArray = [owner, ...resources, ...labourStrings];



    const newAllocation = {
      scheduledBy,
      resources: resourcesArray, // Send as array
      scopeOfWorks,
      owner, // Optional: Include if backend still expects a separate owner field
      startTime: formattedStartTime,
      finishTime: formattedFinishTime,
      status: 'Dispatched',
      labourEntries: labourEntries.filter(
        (entry) => entry.additionalLabour !== '' && entry.labourCompany !== ''
      ),
      attendanceIdentificationNumber: attendanceIdentifier,
      account,
      address,
      contactName,
      contactPhone,
      contactMobile,
      siteContactEmail,
      clientType,
      jobCategory,
      lastModifiedAt: formattedLastModifiedAt,
      workOrderNumber: workOrder,

    };

    try {
      const token = localStorage.getItem('token');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/resource-allocation`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newAllocation),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to save resource allocation: ${errorText}`);
      }

      const savedAllocation = await response.json();

      // Update scheduledJobs state
      setScheduledJobs((prevScheduledJobs) => [...prevScheduledJobs, savedAllocation]);

      // Optionally, display a success message
      alert('Resource allocation saved successfully!');

      // Reset form fields
      setWorkOrder('');
      setResources([]);
      setScopeOfWorks('');
      setStartTime(null);
      setFinishTime(null);
      setOwner(''); // Reset 'owner'
      setLabourEntries([{ additionalLabour: '', labourCompany: '' }]);
      setAccount('');
      setAddress('');
      setContactName('');
      setContactPhone('');
      setContactMobile('');
      setSiteContactEmail('');
      setClientType('');
      setJobCategory('');
      setAttendanceIdentifier('');
    } catch (error) {
      console.error('Error saving resource allocation:', error);
      if (error.message.includes("Work Order Number cannot be null or empty")) {
        alert("Work Order Number is required. Please ensure you've selected a valid Work Order.");
      } else {
        alert(`Failed to save resource allocation: ${error.message}`);
      }
    }
  };

  // Labour Entry Handlers
  const addLabourEntry = () => {
    setLabourEntries([
      ...labourEntries,
      { additionalLabour: '', labourCompany: '' },
    ]);
  };

  const removeLabourEntry = (index) => {
    setLabourEntries(labourEntries.filter((_, i) => i !== index));
  };

  const handleLabourChange = (index, field, value) => {
    const updatedLabourEntries = [...labourEntries];
    updatedLabourEntries[index][field] = value;
    setLabourEntries(updatedLabourEntries);
  };

  // Filter Resources to Exclude Owner
  const filteredResources = ownerAndResourceOptions.filter(
    (option) => option !== owner
  );

  return (
    <div>
      <AdminHeader />
      <Box sx={{ width: '100%' }}>
        <Paper elevation={3}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="resource allocation tabs"
          >
            <Tab label="+ Schedule Resource" />
            <Tab label="Scheduled Jobs" />
            <Tab label="Resources" />
          </Tabs>

          {/* Schedule Resource Tab */}
          {value === 0 && (
            <Box
              component="form"
              className="resource-allocation-form"
              sx={{ padding: 2 }}
              onSubmit={handleSubmit}
            >
              <Heading>Resource Allocation</Heading>

              {/* Work Order No Dropdown */}
              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="work-order-label">Work Order No</InputLabel>
                <Select
                  labelId="work-order-label"
                  value={workOrder}
                  onChange={handleWorkOrderChange}
                  label="Work Order No"
                  required
                >
                  <MenuItem disabled>Programmed Work Orders</MenuItem>
                  {programmedWorkOrders.map((order) => (
                    <MenuItem key={order} value={order}>
                      {order}
                    </MenuItem>
                  ))}
                  <MenuItem disabled>Normal Work Orders</MenuItem>
                  {normalWorkOrders.map((order) => (
                    <MenuItem key={order} value={order}>
                      {order}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              {/* Read-only Fields for Account and Address */}
              <TextField
                fullWidth
                margin="normal"
                value={account}
                label="Account"
                InputProps={{
                  readOnly: true,
                  style: {
                    backgroundColor: '#f0f0f0',
                    color: '#555',
                  },
                }}
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="normal"
                value={address}
                label="Address"
                InputProps={{
                  readOnly: true,
                  style: {
                    backgroundColor: '#f0f0f0',
                    color: '#555',
                  },
                }}
                variant="outlined"
              />

              {/* Attendance Identifier Field */}
              <TextField
                fullWidth
                margin="normal"
                value={attendanceIdentifier}
                label="Attendance Identifier"
                InputProps={{
                  readOnly: true,
                  style: {
                    backgroundColor: '#f0f0f0',
                    color: '#555',
                  },
                }}
                variant="outlined"
              />

              {/* Owner Dropdown */}
              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="owner-label">Owner</InputLabel>
                <Select
                  labelId="owner-label"
                  value={owner}
                  onChange={(e) => setOwner(e.target.value)}
                  label="Owner"
                  required
                >
                  {ownerAndResourceOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              {/* Resources Dropdown */}
              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="resources-label">Resources</InputLabel>
                <Select
                  labelId="resources-label"
                  multiple
                  value={resources}
                  onChange={(e) => setResources(e.target.value)}
                  label="Resources"
                >
                  {filteredResources.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              {/* Additional Labour Section */}
              <LabourContainer>
                {labourEntries.map((entry, index) => (
                  <LabourFields key={index}>
                    <StyledFormControl fullWidth margin="normal" variant="outlined">
                      <InputLabel id={`labour-label-${index}`}>Additional Labour</InputLabel>
                      <Select
                        labelId={`labour-label-${index}`}
                        value={entry.additionalLabour}
                        onChange={(e) =>
                          handleLabourChange(index, 'additionalLabour', e.target.value)
                        }
                        label="Additional Labour"
                      >
                        {Array.from({ length: 11 }, (_, i) => (
                          <MenuItem key={i} value={i}>
                            +{i}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledFormControl>

                    <TextField
                      fullWidth
                      margin="normal"
                      value={entry.labourCompany}
                      onChange={(e) =>
                        handleLabourChange(index, 'labourCompany', e.target.value)
                      }
                      label="Labour Company"
                      variant="outlined"
                    />

                    {index > 0 && (
                      <Button
                        className="delete-button"
                        onClick={() => removeLabourEntry(index)}
                        variant="contained"
                        color="secondary"
                      >
                        Delete
                      </Button>
                    )}
                  </LabourFields>
                ))}

                <Button
                  className="add-button"
                  onClick={addLabourEntry}
                  variant="contained"
                  color="primary"
                >
                  + Add Labour Company
                </Button>
              </LabourContainer>

              {/* Scope Of Works Field */}
              <TextField
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={scopeOfWorks}
                onChange={(e) => setScopeOfWorks(e.target.value)}
                label="Scope Of Work"
                variant="outlined"
                required
              />

              {/* Start and Finish Time Pickers */}
              <TimeContainer>
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Start Time
                  </Typography>
                  <ReactDatePicker
                    selected={startTime}
                    onChange={(date) => setStartTime(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeCaption="Time"
                    placeholderText="Select Start Time"
                    customInput={<TextField fullWidth variant="outlined" />}
                  />
                </Box>

                <Box>
                  <Typography variant="h6" gutterBottom>
                    Finish Time
                  </Typography>
                  <ReactDatePicker
                    selected={finishTime}
                    onChange={(date) => setFinishTime(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeCaption="Time"
                    placeholderText="Select Finish Time"
                    customInput={<TextField fullWidth variant="outlined" />}
                  />
                </Box>
              </TimeContainer>

              {/* Scheduled By (Read Only Field) */}
              <TextField
                fullWidth
                margin="normal"
                value={scheduledBy}
                label="Scheduled By"
                InputProps={{
                  readOnly: true,
                  style: {
                    backgroundColor: '#f0f0f0',
                    color: '#555',
                  },
                }}
                variant="outlined"
              />

              {/* Submit Button */}
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button type="submit" variant="contained" color="primary">
                  Schedule Job
                </Button>
              </div>
            </Box>
          )}

          {/* Scheduled Jobs Tab */}
          {value === 1 && (
            <Box sx={{ padding: 2 }}>
              <Heading>Scheduled Jobs</Heading>
              {isLoadingScheduledJobs ? (
                <Typography>Loading...</Typography>
              ) : selectedWorkOrder ? (
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => setSelectedWorkOrder(null)}
                    sx={{ marginBottom: 2 }}
                  >
                    Back to List
                  </Button>
                  <Typography variant="h6" gutterBottom>
                    Work Order No: {selectedWorkOrder.workOrderNumber}
                  </Typography>
                  {selectedWorkOrder.labourEntries && selectedWorkOrder.labourEntries.length > 0 ? (
                    selectedWorkOrder.labourEntries
                      .sort((a, b) =>
                        compareAsc(
                          parse(a.startTime, 'dd-MM-yyyy HH:mm', new Date()),
                          parse(b.startTime, 'dd-MM-yyyy HH:mm', new Date())
                        )
                      )
                      .map((schedule, index) => (
                        <Box key={index} mb={2}>
                          <Paper className="schedule-card" elevation={2}>
                            <Box className="schedule-details" p={2}>
                              <Typography variant="subtitle1">
                                <strong>Scheduled By:</strong> {schedule.scheduledBy}
                              </Typography>
                              <Typography variant="subtitle1">
                                <strong>Resources Selected:</strong> {formatResources(schedule.resources)}
                              </Typography>
                              <Typography variant="subtitle1">
                                <strong>Scope of Work:</strong> {schedule.scopeOfWorks}
                              </Typography>
                              <Typography variant="subtitle1">
                                <strong>Start Time:</strong> {schedule.startTime || 'N/A'}
                              </Typography>
                              <Typography variant="subtitle1">
                                <strong>Finish Time:</strong> {schedule.finishTime || 'N/A'}
                              </Typography>
                              <Typography variant="subtitle1">
                                <strong>Status:</strong> {schedule.status}
                              </Typography>
                            </Box>
                          </Paper>
                        </Box>
                      ))
                  ) : (
                    <Typography>No schedules for this work order.</Typography>
                  )}
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="scheduled jobs table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Work Order No</StyledTableCell>
                        <StyledTableCell>Scheduled By</StyledTableCell>
                        <StyledTableCell>Resources Selected</StyledTableCell>
                        <StyledTableCell>Start Time</StyledTableCell>
                        <StyledTableCell>Finish Time</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scheduledJobs.map((allocation) => (
                        <TableRow key={allocation.id}>
                          <TableCell>
                            <Button
                              variant="text"
                              onClick={() => handleWorkOrderClick(allocation)}
                            >
                              {allocation.workOrderNumber}
                            </Button>
                          </TableCell>
                          <TableCell>{allocation.scheduledBy || 'N/A'}</TableCell>
                          <TableCell>{formatResources(allocation.resources)}</TableCell>
                          <TableCell>{allocation.startTime || 'N/A'}</TableCell>
                          <TableCell>{allocation.finishTime || 'N/A'}</TableCell>
                          <TableCell>{allocation.status || 'N/A'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}

          {/* Resources Tab */}
          {value === 2 && (
            <Box sx={{ padding: 2 }}>
              <Heading>Resources</Heading>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 2,
                }}
              >
                <Box>
                  <Typography variant="h6">
                    {format(dateRange.startDate, 'dd-MM-yyyy')} - {format(dateRange.endDate, 'dd-MM-yyyy')}
                  </Typography>
                </Box>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ReactDatePicker
                      selected={dateRange.startDate}
                      onChange={(date) =>
                        setDateRange((prev) => ({
                          ...prev,
                          startDate: date,
                        }))
                      }
                      selectsStart
                      startDate={dateRange.startDate}
                      endDate={dateRange.endDate}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Start Date"
                      customInput={<TextField variant="outlined" />}
                    />
                    <Box sx={{ mx: 2 }}>to</Box>
                    <ReactDatePicker
                      selected={dateRange.endDate}
                      onChange={(date) =>
                        setDateRange((prev) => ({
                          ...prev,
                          endDate: date,
                        }))
                      }
                      selectsEnd
                      startDate={dateRange.startDate}
                      endDate={dateRange.endDate}
                      minDate={dateRange.startDate}
                      maxDate={getEndOfFirst30Days()}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="End Date"
                      customInput={<TextField variant="outlined" />}
                    />
                  </Box>
                  <Button
                    variant="text"
                    onClick={() =>
                      setDateRange({
                        startDate: getStartOfMonth(),
                        endDate: getEndOfFirst30Days(),
                      })
                    }
                    sx={{
                      marginTop: 1,
                      color: 'white', // Text color
                      backgroundColor: '#005b5b', // Default background color (green)
                      '&:hover': {
                        backgroundColor: '#004747', // Slightly less green on hover
                      },
                    }}
                  >
                    Reset to Current Month
                  </Button>
                </Box>
              </Box>

              {isLoadingResourceAllocations ? (
                <Typography>Loading...</Typography>
              ) : (
                resourceAllocations.map(({ resourceName, schedules }) => (
                  <Box key={resourceName} ref={technicianRefs.current[resourceName]} mb={4}>
                    <Typography
                      variant="h5"
                      sx={{ marginTop: 4, textAlign: 'center', fontWeight: 'bold' }}
                    >
                      {resourceName}
                    </Typography>
                    {schedules && schedules.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label={`${resourceName} schedule table`}>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Date</StyledTableCell>
                              <StyledTableCell>Time Slot</StyledTableCell>
                              <StyledTableCell>Account</StyledTableCell>
                              <StyledTableCell>Address</StyledTableCell>
                              <StyledTableCell>Scope of Works</StyledTableCell>
                              <StyledTableCell>Status</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {schedules.map((schedule) => {
                              // Parse the date string using the specific format
                              const startDate = parse(schedule.startTime, 'dd-MM-yyyy HH:mm', new Date());
                              const finishDate = parse(schedule.finishTime, 'dd-MM-yyyy HH:mm', new Date());

                              const isStartValid = isValid(startDate);
                              const isFinishValid = isValid(finishDate);

                              return (
                                <TableRow key={schedule.id}>
                                  <TableCell>
                                    {isStartValid ? format(startDate, 'dd-MM-yyyy') : 'Invalid date'}
                                  </TableCell>
                                  <TableCell>
                                    {isStartValid && isFinishValid
                                      ? `${format(startDate, 'HH:mm')} - ${format(finishDate, 'HH:mm')}`
                                      : 'Invalid time'}
                                  </TableCell>
                                  <TableCell>{schedule.account || 'N/A'}</TableCell>
                                  <TableCell>{schedule.address || 'N/A'}</TableCell>
                                  <TableCell>{schedule.scopeOfWorks}</TableCell>
                                  <TableCell>{schedule.status}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography>No allocations for this resource in the selected date range.</Typography>
                    )}
                  </Box>
                ))
              )}
            </Box>
          )}
        </Paper>
      </Box>
      <AdminFooter />
    </div>
  );
}

export default ResourceAllocation;
