import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

const NotFound = () => {
  const navigate = useNavigate(); // Initialize useNavigate to programmatically navigate

  useEffect(() => {
    // Auto redirect to /Admin after 5 seconds
    const timer = setTimeout(() => {
      navigate("/Admin"); // Redirect to the admin page
    }, 5000);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      fontFamily: "'Nunito', sans-serif",
      textAlign: 'center',
      backgroundColor: 'ffff',
      flexDirection: 'column',
    },
    notfound: {
      maxWidth: '560px',
      width: '100%',
      padding: '20px',
      lineHeight: '1.1',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    notfound404: {
      display: 'inline-block',
      width: '300px',
      height: '300px',
      marginBottom: '20px',
      backgroundImage: "url('/Black Logo.png')", // Correct image path
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '50%',
    },
    h1: {
      fontSize: '100px',
      fontWeight: '700',
      marginTop: '0px',
      marginBottom: '10px',
      color: '#151723',
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '21px',
      fontWeight: '400',
      margin: '0',
      textTransform: 'uppercase',
      color: '#151723',
    },
    p: {
      color: '#999fa5',
      fontWeight: '400',
      margin: '20px 0',
    },
    link: {
      display: 'inline-block',
      fontWeight: '700',
      borderRadius: '40px',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#005B5B',
      padding: '10px 20px',
      marginTop: '20px',
      fontSize: '16px',
      transition: 'background-color 0.3s',
    },
    linkHover: {
      backgroundColor: '#004949',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.notfound}>
        <div style={styles.notfound404}></div>
        <h1 style={styles.h1}>404</h1>
        <h2 style={styles.h2}>Oops! Page Not Be Found</h2>
        <p style={styles.p}>
          Sorry, but the page you are looking for does not exist, has been removed, the name changed, or is temporarily unavailable.
        </p>
        <a href="/Admin" style={styles.link}>Back to homepage</a>
        <p style={styles.p}>You will be redirected to the admin page in 5 seconds...</p>
      </div>
    </div>
  );
};

export default NotFound;
